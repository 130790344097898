import { Grid, Typography } from "@mui/material";
import { BottleInsertedEvent, BottleRemovedEvent } from "./event-definitions";
import { mdiGlassWine, mdiBottleWine } from "@mdi/js";
import Icon from '@mdi/react';
import { BottleCategory } from "./shadow-definitions";
import { ProductDetails } from "./services/products-service";

interface FridgeEventToastProps {
    event: BottleInsertedEvent
         | BottleRemovedEvent,
    product: ProductDetails,
}

function getIconColourFromWineCategory(category: BottleCategory) {
    switch (category) {
        case "RED": return "#8E1D3B";
        case "ROSE": return "#D86864";
        case "WHITE": return "#ACB183";
        case "SPARKLING": return "#A18129";
    }
}

function getWineTypeText(category: BottleCategory) {
    switch (category) {
        case "ROSE": return "ROSÉ";
        default: return category;
    }
}

function FridgeEventToast({ event, product }: FridgeEventToastProps): JSX.Element {

    const actionMessage = event.type === "BottleInserted"
        ? "bottle inserted"
        : "bottle removed";

    const iconPath = event.type === "BottleInserted"
        ? mdiBottleWine
        : mdiGlassWine;

    const iconComponent = event.type === "BottleInserted" && product.imageURL
        ? <img src={product.imageURL} alt={product.name} style={{ width: "80%", padding: "10px"}} />
        : <Icon path={iconPath} size={3} color={getIconColourFromWineCategory(event.bottle.category)} />

    return <Grid container alignItems="center">
        <Grid item xs={4} textAlign="left">
            {iconComponent}
        </Grid>
        <Grid item xs={8}>
            <Typography sx={{ fontSize: 14 }} align="left" gutterBottom>
                <b>{event.fridgeId}</b>
            </Typography>
            <Typography variant="h5" component="div" align="left" gutterBottom>
                <b style={{textTransform: "capitalize"}}>{product.name}</b> {actionMessage}
            </Typography>
            <Typography sx={{ fontSize: 14 }} align="left" gutterBottom>
                Category: <i style={{textTransform: "capitalize"}}>{getWineTypeText(event.bottle.category).toLowerCase()}</i>
            </Typography>
            <Typography sx={{ fontSize: 14 }} align="left" gutterBottom>
                SKU: <i>{event.bottle.sku}</i>
            </Typography>
            <Typography sx={{ fontSize: 14 }} align="left" gutterBottom>
                Serial: <i>{event.bottle.serial}</i>
            </Typography>
        </Grid>
    </Grid>;
}

export default FridgeEventToast;