import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PubSub, Auth } from 'aws-amplify';
import FridgeEventToast from './FridgeEventToast';
import ReorderList from './ReorderList';
import { Container, AppBar, Toolbar, Typography, Box, Stack, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { FridgeEvent } from './event-definitions';
import { getProductDetailFromSku } from './services/products-service';


function App() {

  useEffect(() => {
    console.log('Subscribing to fridge event topic');
    const fridgeEventTopic = PubSub.subscribe('aircellar/fridge/+/events');

    const sub = fridgeEventTopic.subscribe({
      next: async data => {
        console.log('Message received', data);
        const fridgeEvent = data.value as FridgeEvent;
        
        if (fridgeEvent.type !== "BottleReordered") {
          const productDetails = await getProductDetailFromSku(fridgeEvent.bottle?.sku);

          toast.success(<FridgeEventToast event={data.value} product={productDetails} />, {
            icon: '',
          });
        }
      },
      error: error => console.error(error),
      complete: () => console.log('Done'),
    });

    return () => {
      console.log('Unsub from fridge event topic')
      sub.unsubscribe();
    }

  }, []);

  return (
    <div className="App">
      <CssBaseline />
      <AppBar position="fixed" sx={{ bgcolor: "#171717" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <svg width="40" height="40" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M40.6952 79.7547C18.7296 79.7547 0.858765 61.8661 0.858765 39.8787C0.858765 17.8914 18.7296 0 40.6952 0C62.6607 0 80.5343 17.8886 80.5343 39.8787C80.5343 61.8689 62.6635 79.7547 40.6952 79.7547ZM40.6952 2.10124C19.8855 2.10124 2.97192 19.0512 2.97192 39.8787C2.97192 60.7062 19.8995 77.6534 40.7091 77.6534C61.5188 77.6534 78.4492 60.709 78.4492 39.8787C78.4492 19.0484 61.5048 2.10124 40.6952 2.10124Z" fill="white" />
                  <path d="M29.6675 17.1573C31.2209 15.0561 31.7667 17.2358 31.7667 17.2358C31.7667 17.2358 25.0829 39.8759 41.2493 39.8759C57.4156 39.8759 48.8202 18.5694 48.4172 17.0901C48.0141 15.6108 49.6515 15.8406 50.1889 16.7735C50.7263 17.7065 60.3236 38.5311 42.338 42.3722C41.795 42.4507 41.9882 60.8435 42.2205 63.1044C42.4528 65.3654 49.1505 66.2647 49.4976 66.1863C50.2169 66.1666 50.48 67.2453 49.4976 67.1192H31.6099C30.9886 67.1192 30.6807 66.3572 31.6883 66.0294C32.6959 65.7016 40.446 66.0294 40.5495 63.1521C40.6531 60.2748 41.1569 42.4619 40.5495 42.375C39.9422 42.2882 22.8942 40.8873 29.6675 17.1573Z" fill="white" />
                  <path d="M32.3992 26.809C32.3992 26.809 30.44 38.1753 41.1625 38.1641C51.5184 38.1641 49.1309 27.9297 49.1309 27.9297C42.7635 32.6029 38.442 26.2655 32.3992 26.809Z" fill="white" />
                </svg>
                <svg width="110" height="27" viewBox="0 0 220 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 43.1968C0 35.3046 5.9812 31.1946 19.8048 29.5752C19.6957 25.6137 18.2431 22.3133 13.6893 22.3133C10.3306 22.3133 7.18751 23.9635 4.2235 25.9555L1.31547 20.1812C5.02118 17.6317 9.79326 15.3512 15.1419 15.3512C23.5637 15.3512 27.7061 21.0217 27.7061 31.2842V53.0951H21.2323L20.6277 49.0187H20.4094C17.3922 51.84 13.9356 54 9.9584 54C4.05837 54 0 49.6182 0 43.1968ZM19.8048 43.2277V34.946C10.6693 36.2067 7.62693 38.8179 7.62693 42.5385C7.62693 45.794 9.65612 47.2172 12.4802 47.2172C15.2231 47.2116 17.3082 45.7492 19.8048 43.2277Z" fill="white"/>
                  <path d="M33.7544 4.90664C33.7544 1.99572 35.8368 0.0149536 38.6356 0.0149536C41.4345 0.0149536 43.5197 1.99572 43.5197 4.90664C43.5197 7.75592 41.4345 9.79832 38.6356 9.79832C35.8368 9.79832 33.7544 7.75872 33.7544 4.90664ZM34.6584 16.2477H42.5596V53.0951H34.6584V16.2477Z" fill="white"/>
                  <path d="M49.7052 16.2506H56.179L56.7808 22.8372H57.0019C59.4005 18.0352 62.9663 15.3652 66.574 15.3652C68.2897 15.3652 69.3729 15.6062 70.3889 16.1161L69.0174 23.6161C67.9066 23.2252 66.7383 23.0226 65.5608 23.0166C62.8711 23.0166 59.6356 25.0281 57.6036 30.6399V53.0951H49.7052V16.2506Z" fill="white"/>
                  <path d="M70.7303 34.705C70.7303 22.4338 78.6568 15.3512 87.6272 15.3512C91.9066 15.3512 95.033 17.1527 97.4764 19.492L93.6335 25.0954C91.8507 23.3835 90.1517 22.3946 88.0358 22.3946C82.6032 22.3946 78.8471 27.3143 78.8471 34.6966C78.8471 42.079 82.55 46.9399 87.7895 46.9399C90.4232 46.9399 92.8275 45.539 94.7559 43.76L97.9606 49.4697C95.0213 52.3529 91.0763 53.9777 86.961 54C77.7779 54 70.7303 46.9314 70.7303 34.705Z" fill="white"/>
                  <path d="M100.2 34.705C100.2 22.7336 107.799 15.3512 115.728 15.3512C124.835 15.3512 129.635 22.5514 129.635 32.8447C129.642 34.2529 129.527 35.659 129.291 37.0472H107.897C108.557 43.5863 112.397 47.3097 117.827 47.3097C120.71 47.3097 123.178 46.3179 125.647 44.6369L128.334 50.0077C125.126 52.3807 121.057 54 116.786 54C107.477 54 100.2 46.8586 100.2 34.705ZM122.803 31.1637C122.803 25.4932 120.5 22.0415 115.893 22.0415C111.975 22.0415 108.512 25.2522 107.827 31.1637H122.803Z" fill="white"/>
                  <path d="M135.418 43.6171V0.107422H143.316V44.0682C143.316 46.1974 144.195 46.9483 145.018 46.9483C145.435 46.9594 145.851 46.9085 146.252 46.797L147.24 53.2408C146.28 53.661 144.965 53.9916 143.07 53.9916C137.475 54 135.418 50.0385 135.418 43.6171Z" fill="white"/>
                  <path d="M150.915 43.6171V0.107422H158.817V44.0682C158.817 46.1974 159.695 46.9483 160.516 46.9483C160.933 46.9593 161.35 46.9083 161.753 46.797L162.738 53.2408C161.778 53.661 160.462 53.9916 158.57 53.9916C152.973 54 150.915 50.0385 150.915 43.6171Z" fill="white"/>
                  <path d="M164.521 43.1968C164.521 35.3045 170.502 31.1945 184.328 29.5752C184.216 25.6136 182.764 22.3133 178.21 22.3133C174.851 22.3133 171.708 23.9634 168.747 25.9554L165.839 20.1924C169.542 17.6429 174.314 15.3624 179.663 15.3624C188.084 15.3624 192.227 21.0329 192.227 31.2954V53.0951H185.753L185.151 49.0131H184.93C181.913 51.8343 178.456 53.9944 174.479 53.9944C168.582 54 164.521 49.6182 164.521 43.1968ZM184.328 43.2276V34.9459C175.193 36.2067 172.148 38.8178 172.148 42.5384C172.148 45.794 174.177 47.2172 177.004 47.2172C179.747 47.2116 181.832 45.7491 184.328 43.2276Z" fill="white"/>
                  <path d="M199.316 16.2506H205.79L206.395 22.8372H206.613C209.014 18.0352 212.58 15.3652 216.188 15.3652C217.904 15.3652 218.987 15.6062 220 16.1161L218.629 23.6161C217.518 23.2247 216.349 23.0221 215.172 23.0166C212.485 23.0166 209.247 25.0281 207.218 30.6399V53.0951H199.316V16.2506Z" fill="white"/>
                </svg>
              </Stack>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container>
        <Box sx={{ my: 2, mt: 12 }}>
          <ReorderList />
        </Box>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default App;
