import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub'

const AWS_REGION = 'ap-southeast-2';
const AWS_COGNITO_IDENTITY_POOL = 'ap-southeast-2:4b0afe48-393e-4ea0-b2eb-8e2bb9e2bfb7';
const IOT_CORE_ENDPOINT = 'wss://iotcore.aircellar.lx-cloud.com/mqtt'

Amplify.configure({
  Auth: {
    identityPoolId: AWS_COGNITO_IDENTITY_POOL,
    region: AWS_REGION,
  }
})

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: AWS_REGION,
  aws_pubsub_endpoint: IOT_CORE_ENDPOINT,
}));


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
