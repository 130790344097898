import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { ProductDetails } from "./services/products-service";
import CloseIcon from '@mui/icons-material/Close';
import { fontWeight, maxHeight } from "@mui/system";

interface ProductDetailDialogProps {
    product: ProductDetails | null,
    onClose: () => void,
}

function InfoRow(props: { label: string, value: string }): JSX.Element {
    return <TableRow>
        <TableCell component={"th"} sx={{ fontWeight: "bold" }}>{props.label}</TableCell>
        <TableCell>
            {props.value}
        </TableCell>
    </TableRow>;
}

export default function ProductDetailDialog(props: ProductDetailDialogProps): JSX.Element {
    const { product, onClose } = props;

    return <Dialog
        open={product != null}
        onBackdropClick={onClose}
        fullWidth={true}
        maxWidth={"sm"}>
        <DialogTitle>
            {product?.name}
            {/* Product Details */}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <img src={product?.imageURL} style={{
                        maxWidth: "100%",
                        maxHeight: "calc(100vh - 180px)"
                    }} />
                </Grid>
                <Grid item xs={8}>
                    <Table>
                        <TableBody>
                            <InfoRow label="Origin" value={product?.location ?? "N/A"} />
                            <InfoRow label="Year" value={product?.year || "N/A"} />
                            <InfoRow label="Star Rating" value={product?.starRating?.toString() ?? "N/A"} />
                            <InfoRow label="Number of Ratings" value={product?.numberOfRatings?.toString() ?? "N/A"} />
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}