import moment from "moment";
import { Button, CircularProgress, Grid, Typography, Box } from "@mui/material";
import { PubSub } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { debounceTime, interval, Observable } from "rxjs";
import axios from "axios";
import { BottleCategory } from "./shadow-definitions";
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getProductDetailFromSku, ProductDetails } from "./services/products-service";
import { margin } from "@mui/system";
import ProductDetailDialog from "./ProductDetailDialog";
import { stringify } from "querystring";

const ApiEndpoint = "https://api.aircellar.lx-cloud.com/reorders";

function zenToRx<T>(zenObservable: ReturnType<typeof PubSub.subscribe>): Observable<T> {
    return new Observable(observer => zenObservable.subscribe(observer));
}

export interface ReorderTableRecord {
    demoId: 'AircellarDemo',
    bottleSerial: string,
    sku: string,
    bottleCategory: BottleCategory,
    customerFridgeId: string,
    removedTimestamp: number,
    /** 
     * The time after which this record should be considered to be active. 
     * Before this time it is more like a pending order as the customer may just be looking at it
     * and put it back in their fridge.
     * 
     * In a real system the delay would likely be something like 12 hours but in the demo of the system
     * it may be more like 60 seconds.
     * */
    reorderAtTimestamp: number,
}

function getWineTypeText(category: BottleCategory) {
    switch (category) {
        case "ROSE": return "ROSÉ";
        default: return category;
    }
}

function useProductDetail(sku: string): [product: ProductDetails | null, loaded: boolean] {
    const [loaded, setLoaded] = useState(false);
    const [product, setProduct] = useState<ProductDetails | null>(null);

    useEffect(() => {
        getProductDetailFromSku(sku).then(pd => {
            setLoaded(true);
            setProduct(pd);
        });
    }, [sku]);

    return [product, loaded];
}

function ProductName(props: { sku: string }): JSX.Element {

    const [product, productLoaded] = useProductDetail(props.sku);

    return product && productLoaded
        ? <>
            <img alt={product?.name} src={product?.imageURL} style={{ height: "80px", marginRight: "10px" }} />
            <span title={product?.name}>{product?.name}</span>
        </>
        : <span></span>;
}

function SkuButton(props: { 
    sku: string, 
    onClick: (product: ProductDetails) => void }
    ): JSX.Element {

    const [product, productLoaded] = useProductDetail(props.sku);

    const clickHandler = product
        ? () => props.onClick(product)
        : undefined;

    return <Button onClick={clickHandler} disabled={!product}>{props.sku}</Button>
}


function ReorderList(): JSX.Element {

    const [isLoading, setIsLoading] = useState(true);
    const [now, setNow] = useState(new Date().getTime());
    const [allReorders, setAllReoarders] = useState<ReorderTableRecord[]>([]);
    const [openProduct, setOpenProduct] = useState<ProductDetails | null>(null);

    async function sendReplacement(row: ReorderTableRecord) {
        const resendResponse = await axios.post(`${ApiEndpoint}/send`, {
            customerFridgeId: row.customerFridgeId,
            bottleSerial: row.bottleSerial,
        });

        console.log(resendResponse);
    }

    const displayColumns = useMemo(() => {
        return [
            {
                field: "bottleCategory",
                headerName: "Product",
                width: 270,
                valueFormatter: (v) => {
                    const typeText = getWineTypeText(v.value as BottleCategory);
                    return `${typeText.substring(0, 1)}${typeText.substring(1).toLowerCase()}`;
                },
                renderCell: (v) => {
                    return <ProductName sku={(v.row as ReorderTableRecord)?.sku} />;
                }
            },
            {
                field: "sku",
                headerName: "SKU",
                width: 200,
                renderCell: (v) => {
                    const reorderRow = v.row as ReorderTableRecord;
                    return <SkuButton sku={reorderRow.sku} onClick={(p) => setOpenProduct(p)} />
                }
            },
            {
                field: "customerFridgeId",
                headerName: "Customer",
                width: 130,
            },
            {
                field: "removedTimestamp",
                headerName: "Removed Time",
                width: 350,
                valueFormatter: (v) => {
                    const m = moment(v.value as number);
                    return `${m.format('MMM Do YYYY, h:mm:ss a')} (${m.fromNow()})`;
                },
            }
        ] as GridColumns;
    }, []);

    const actionColumns = useMemo(() => {
        return [
            {
                field: "actions",
                headerName: "Actions",
                width: 180,
                renderCell: (p: GridRenderCellParams<any>) => (
                    <Button onClick={() => sendReplacement(p.row)}>Clear</Button>
                )
            }
        ] as GridColumns;
    }, []);

    async function refreshList() {
        const reorderListResult = await axios.get(ApiEndpoint);
        setIsLoading(false);
        console.log(reorderListResult);

        const allReorderRecords = (reorderListResult.data as ReorderTableRecord[])
            .sort((a, b) => a.removedTimestamp - b.removedTimestamp);

        setAllReoarders(allReorderRecords);
    }

    async function clearAllReorders() {
        setIsLoading(true);
        const clearResult = await axios.delete(ApiEndpoint);
        setIsLoading(false);
        setAllReoarders([]);
    }

    useEffect(() => {

        const fridgeEvents = zenToRx<any>(PubSub.subscribe('aircellar/fridge/+/events'));

        const fridgeEventSub = fridgeEvents.pipe(
            debounceTime(2000),
        ).subscribe(_ => refreshList());

        const tickSub = interval(5000).subscribe(_ => setNow(new Date().getTime()));

        refreshList();

        return () => {
            fridgeEventSub.unsubscribe();
            tickSub.unsubscribe();
        };
    }, [])

    if (isLoading) {
        return <CircularProgress />;
    }
    else {
        const activeReorders = allReorders.filter(r => r.reorderAtTimestamp < now);
        const pendingReorders = allReorders.filter(r => r.reorderAtTimestamp >= now);

        return <Grid container direction="row" alignItems="stretch" rowSpacing="8">
            <Grid item xs={12}>
                <Typography variant="h4" textAlign="left" gutterBottom>
                    Reordered Wines
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <div style={{ height: 500 }}>
                    <DataGrid
                        rowHeight={100}
                        rows={activeReorders.map(r => ({ id: r.bottleSerial, ...r }))}
                        columns={[...displayColumns, ...actionColumns]}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Box mt={5}>
                    <Typography variant="h4" textAlign="left" gutterBottom>
                        Pending Reorder
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <div style={{ height: 500 }}>
                    <DataGrid
                        rowHeight={100}
                        rows={pendingReorders.map(r => ({ id: r.bottleSerial, ...r }))}
                        columns={displayColumns}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={clearAllReorders}>
                    Clear All Reorders
                </Button>
            </Grid>
            <ProductDetailDialog product={openProduct} onClose={() => setOpenProduct(null)} />
        </Grid>
    }

}

export default ReorderList;